<template>
  <section class="section about-our-platform">
    <div class="section-header">
      <h3 class="section-title ">{{ sectionTexts.title }}</h3>

      <h2 class="section-description">{{ sectionTexts.description }}</h2>
    </div>

    <div class="section-content">
      <div class="video-wrapper">
        <video 
          ref="videoPlayer"
          class="video-player"
          @timeupdate="updateProgress"
          @loadedmetadata="initializeVideo"
        >
          <source :src="videoSrc" type="video/mp4">
        </video>
        <div class="video-controls" :class="{ 'is-playing': isPlaying }">
          <button 
            class="control-button"
            @click="toggleVideo"
          >
            <svg 
              v-show="!isPlaying" 
              class="control-icon play-icon" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5v14l11-7z" fill="currentColor"/>
            </svg>
            <svg 
              v-show="isPlaying" 
              class="control-icon pause-icon" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" fill="currentColor"/>
            </svg>
          </button>
        </div>
        <div class="time-control">
          <div class="progress-bar" @click="seek">
            <div class="progress" :style="{ width: `${progress}%` }"></div>
          </div>
          <div class="time-display">{{ currentTime }} / {{ duration }}</div>
        </div>
      </div>

      <div class="paragraphs">
        <div class="paragraph">
          <h4 class="paragraph-title">
            <span class="paragraph-icon image"></span>

            <span>{{ sectionTexts.paragraph1.title }}</span>
          </h4>

          <p class="paragraph-description">{{ sectionTexts.paragraph1.description }}</p>
        </div>

        <div class="paragraph">
          <h4 class="paragraph-title">
            <span class="paragraph-icon people"></span>

            <span>{{ sectionTexts.paragraph2.title }}</span>
          </h4>

          <p class="paragraph-description">{{ sectionTexts.paragraph2.description }}</p>
        </div>

        <div class="paragraph">
          <h4 class="paragraph-title">
            <span class="paragraph-icon figures"></span>

            <span>{{ sectionTexts.paragraph3.title }}</span>
          </h4>

          <p class="paragraph-description">{{ sectionTexts.paragraph3.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {onMounted, ref} from 'vue';

export default {
  name: 'AboutOurPlatform',
  setup() {
    const sectionTexts = {
      title: 'Czym jest Profil Pamięci?',
      description: 'To miejsce rodzinnych historii, zdjęć oraz opisów radosnych i wyjątkowych chwil z życia.',
      paragraph1: {
        title: 'Ważne momenty',
        description: 'Zdecyduj, które momenty z życia opisywanej osoby były istotne. Ślub, narodziny dzieci, pierwsza praca lub inne wydarzenia.',
      },
      paragraph2: {
        title: 'Rodzinne historie',
        description: 'Historie, opowieści i anegdoty, które ukształtowały waszą rodzinę nie przeminą wraz z upływającym czasem.',
      },
      paragraph3: {
        title: 'Łączenie pokoleń',
        description: 'Profil Pamięci pozwala tworzyć wyjątkowe opisy zdjęć, pasji i zainteresowań, a nawet budować drzewo genealogiczne, które połączy pokolenia.',
      },
    };

    const apiUrl = process.env.VUE_APP_API_URL;

    const videoPlayer = ref(null);
    const isPlaying = ref(false);
    const isToggling = ref(false);
    const videoSrc = new URL(`${apiUrl}/media/videos/about_memorial_profile.mp4`, import.meta.url).href;
    const progress = ref(0);
    const currentTime = ref('0:00');
    const duration = ref('0:00');

    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const updateProgress = () => {
      if (videoPlayer.value) {
        progress.value = (videoPlayer.value.currentTime / videoPlayer.value.duration) * 100;
        currentTime.value = formatTime(videoPlayer.value.currentTime);
      }
    };

    const initializeVideo = () => {
      if (videoPlayer.value) {
        duration.value = formatTime(videoPlayer.value.duration);
      }
    };

    const seek = (event) => {
      if (videoPlayer.value) {
        const progressBar = event.currentTarget;
        const clickPosition = event.offsetX;
        const progressBarWidth = progressBar.offsetWidth;
        videoPlayer.value.currentTime = (clickPosition / progressBarWidth) * videoPlayer.value.duration;
      }
    };

    const toggleVideo = async () => {
      if (isToggling.value || !videoPlayer.value) return;
      
      isToggling.value = true;
      try {
        if (videoPlayer.value.paused) {
          await videoPlayer.value.play();
          isPlaying.value = true;
        } else {
          videoPlayer.value.pause();
          isPlaying.value = false;
        }
      } catch (error) {
        console.error('Video playback error:', error);
        isPlaying.value = false;
      } finally {
        setTimeout(() => {
          isToggling.value = false;
        }, 300);
      }
    };

    onMounted(() => {
      if (videoPlayer.value) {
        videoPlayer.value.load();
        isPlaying.value = false;
        videoPlayer.value.addEventListener('pause', () => {
          isPlaying.value = false;
        });
        videoPlayer.value.addEventListener('play', () => {
          isPlaying.value = true;
        });
      }
    });

    return {
      sectionTexts,
      videoPlayer,
      videoSrc,
      isPlaying,
      progress,
      currentTime,
      duration,
      toggleVideo,
      updateProgress,
      initializeVideo,
      seek,
    };
  },
};
</script>

<style scoped lang="scss">
.about-our-platform {
  width: calc(100% - 32px) !important;
  padding: 24px;
  margin: 0 16px;
  border-radius: 16px;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    width: fit-content;
    padding: 108px;
    gap: 64px;
  }

  .section-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (min-width: 768px) {
      gap: 12px;
    }

    .section-title {
      font-family: 'Source Serif 4', serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;

      @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }

    .section-description {
      font-family: 'Source Serif 4', serif;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 2rem;

      @media (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 3.5rem;
        max-width: 879px;
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      gap: 18px;
      margin-top: 64px;
    }

    .paragraphs {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (min-width: 768px) {
        padding: 22px 40px;
        width: 504px;
        justify-content: space-between;
      }
    }

    .paragraph {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .paragraph-icon {
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;

        @media (min-width: 768px) {
          width: 36px;
          height: 36px;
        }

        &.image {
          background-image: url('../../../assets/images/image-round-grey-24x24.svg');
          background-size: 100%;
        }

        &.people {
          background-image: url('../../../assets/images/people-round-grey-24x24.svg');
          background-size: 100%;
        }

        &.figures {
          background-image: url('../../../assets/images/figures-round-grey-24x24.svg');
          background-size: 100%;
        }
      }

      .paragraph-title {
        display: flex;
        align-items: center;
        gap: 12px;
        font-family: 'Source Serif 4', serif;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;

        @media (min-width: 768px) {
          font-size: 1.25rem;
        }
      }

      .paragraph-description {
        font-family: 'Source Serif 4', serif;
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 400;
        text-align: start;

        @media (min-width: 768px) {
          font-size: 1rem;
        }
      }
    }

    .video-wrapper {
      width: 100%;
      aspect-ratio: 1 / 1;
      background: rgba(#0A0A0A, 0.1);
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media (min-width: 768px) {
        width: 480px;
        height: 480px;
        min-width: unset;
      }

      .video-player {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video-controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        z-index: 1;

        &:not(.is-playing) {
          opacity: 1;
        }

        .control-button {
          background: rgba(255, 255, 255, 0.8);
          border: none;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

          @media (min-width: 768px) {
            width: 96px;
            height: 96px;
          }
        }

        .control-icon {
          width: 32px;
          color: #222222;
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
          opacity: 0.9;
          transition: transform 0.2s ease, opacity 0.2s ease;

          @media (min-width: 768px) {
            width: 70px;
          }

          &:hover {
            transform: scale(1.1);
            opacity: 1;
          }
        }
      }

      &:hover {
        .video-controls {
          opacity: 1;
        }

        .time-control {
          opacity: 1;
        }
      }

      .time-control {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 32px 24px 16px;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 50%, transparent 100%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        z-index: 2;

        .progress-bar {
          width: 100%;
          height: 3px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 1.5px;
          cursor: pointer;
          position: relative;
          transition: height 0.2s ease;

          .progress {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background: #FFFFFF;
            border-radius: 1.5px;
            transition: width 0.1s linear;

            &::after {
              content: '';
              position: absolute;
              right: -4px;
              top: 50%;
              transform: translateY(-50%);
              width: 8px;
              height: 8px;
              background: #FFFFFF;
              border-radius: 50%;
              opacity: 0;
              transition: opacity 0.2s ease;
            }
          }

          &:hover {
            height: 5px;

            .progress::after {
              opacity: 1;
            }
          }
        }

        .time-display {
          color: white;
          font-size: 0.75rem;
          margin-top: 8px;
          font-family: Roboto, sans-serif;
          opacity: 0.9;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
</style>
