<template>
  <div class="section hero">
    <div class="hero-image"></div>

    <div class="text-content">
      <h1 class="hero-header">{{ heroContent.title }}</h1>

      <div>
        <p class="paragraph">
          {{ heroContent.description }}
        </p>

        <div class="paragraph get-profile-button-wrapper">
          <a 
            class="get-profile-button"
            :href="heroContent.buyProfileUrl"
            @click="handleBuyClick"
          >
            <span>{{ heroContent.buyButtonText }}</span>

            <v-icon>
              <img alt="" src="../../../assets/images/external-link-icon-white-16x16.svg" width="16" height="16">
            </v-icon>
          </a>

          <a
            class="see-profile-button"
            :href="heroContent.sampleProfileUrl"
            @click="handleSampleClick"
          >
            {{ heroContent.sampleProfileText }}
          </a>
        </div>
      </div>

      <div class="social-media">
        <span>{{ heroContent.aboutUs }}</span>

        <div class="social-media-button-list">
          <a :href="icon.url" target="_blank" class="social-media-button" v-for="icon of socialMediaButtonList" :key="icon.name" @click="handleSocialMediaClick(icon)">
            <v-icon>
              <img :alt="icon.name" :src="icon.iconPath" width="16" height="16">
            </v-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import tiktokIcon from '@/assets/images/social-media/tiktok-icon-black-16x16.svg';
import facebookIcon from '@/assets/images/social-media/facebook-icon-black-16x16.svg';
import youtubeIcon from '@/assets/images/social-media/youtube-icon-black-16x16.svg';
import instagramIcon from '@/assets/images/social-media/instagram-icon-black-16x16.svg';
import linkedinIcon from '@/assets/images/social-media/linkedin-icon-black-16x16.svg';

export default {
  name: 'HomePageHero',
  setup() {
    const videoPlayer = ref(null);
    const apiUrl = process.env.VUE_APP_API_URL;
    const isVideoVisible = ref(false);
    const isPlaying = ref(false);
    const progress = ref(0);
    const currentTime = ref('0:00');
    const duration = ref('0:00');

    const heroContent = reactive({
      title: 'Uwiecznij historię swoich bliskich.',
      description: 'Najpiękniejsze chwile i wspomnienia przekazywane dalszym pokoleniom.',
      buyProfileUrl: 'https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl',
      sampleProfileUrl: '/profil/55-02-03',
      buyButtonText: 'Zamów teraz',
      sampleProfileText: 'Zobacz Profil Pamięci',
      aboutUs: 'O nas: ',
      playButtonText: 'Zobacz jak to działa',
    });

    const videoContent = reactive({
      shouldShowVideo: true,
      width: 580,
      height: 397,
      url: `${apiUrl}/media/videos/hero.mp4`,
      title: 'TakZyli.pl - platforma do tworzenia Profilu Pamięci',
      allowSettings: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    });

    const socialMediaButtonList = [
      {
        name: 'TikTok',
        url: 'https://www.tiktok.com/@takzyli.pl',
        iconPath: tiktokIcon,
      },
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/profile.php?id=61570198959117',
        iconPath: facebookIcon,
      },
      {
        name: 'YouTube',
        url: 'https://www.youtube.com/@takzyli_pl',
        iconPath: youtubeIcon,
      },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/takzyli.pl',
        iconPath: instagramIcon,
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/takżyli',
        iconPath: linkedinIcon,
      },
    ];

    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const updateProgress = () => {
      if (videoPlayer.value) {
        progress.value = (videoPlayer.value.currentTime / videoPlayer.value.duration) * 100;
        currentTime.value = formatTime(videoPlayer.value.currentTime);
      }
    };

    const initializeVideo = () => {
      if (videoPlayer.value) {
        duration.value = formatTime(videoPlayer.value.duration);
      }
    };

    const seek = (event) => {
      if (videoPlayer.value) {
        const progressBar = event.currentTarget;
        const clickPosition = event.offsetX;
        const progressBarWidth = progressBar.offsetWidth;
        videoPlayer.value.currentTime = (clickPosition / progressBarWidth) * videoPlayer.value.duration;
      }
    };

    const showVideo = () => {
      isVideoVisible.value = true;
      setTimeout(() => {
        if (videoPlayer.value) {
          videoPlayer.value.play().catch(error => {
            console.error('Error playing video:', error);
          });
          isPlaying.value = true;
        }
      }, 0);
    };

    const toggleVideo = () => {
      if (videoPlayer.value) {
        if (videoPlayer.value.paused) {
          videoPlayer.value.play();
          isPlaying.value = true;
        } else {
          videoPlayer.value.pause();
          isPlaying.value = false;
        }
      }
    };

    const handleBuyClick = () => {
      window.dataLayer.push({
        event: 'cta_click',
        cta_type: 'button',
        cta_text: heroContent.buyButtonText,
        cta_location: 'hero',
        cta_url: heroContent.buyProfileUrl,
        cta_action: 'buy_profile'
      });
    };

    const handleSampleClick = () => {
      window.dataLayer.push({
        event: 'cta_click',
        cta_type: 'button',
        cta_text: heroContent.sampleProfileText,
        cta_location: 'hero',
        cta_url: heroContent.sampleProfileUrl,
        cta_action: 'view_sample'
      });
    };

    const handleSocialMediaClick = (socialMedia) => {
      window.dataLayer.push({
        event: 'social_media_click',
        social_platform: socialMedia.name,
        social_url: socialMedia.url,
        cta_location: 'hero'
      });
    };

    // Lifecycle Hooks
    onMounted(() => {
      if (videoPlayer.value) {
        videoPlayer.value.load();
      }
    });

    onUnmounted(() => {
      videoContent.shouldShowVideo = false;
    });

    // Expose to template
    return {
      // Refs
      videoPlayer,
      isVideoVisible,
      isPlaying,
      progress,
      currentTime,
      duration,
      // Reactive state
      heroContent,
      videoContent,
      // Methods
      handleBuyClick,
      handleSampleClick,
      handleSocialMediaClick,
      showVideo,
      toggleVideo,
      updateProgress,
      initializeVideo,
      seek,
      socialMediaButtonList,
    };
  }
};
</script>

<style scoped lang="scss">
.hero {
  font-family: 'Source Serif 4', serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px 16px 48px;
  align-items: center;
  gap: 32px;

  @media (min-width: 768px) {
    padding: 56px 32px 64px;
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-bottom: 4px;
    gap: 160px;
  }

  .hero-header {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0 16px;

    @media (min-width: 768px) {
      font-weight: 600;
      font-size: 3.5rem;
      line-height: 5rem;
      margin-bottom: 12px;
      padding: 0;
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    width: 100%;

    @media (min-width: 768px) {
      width: 612px;
      text-align: left;
      margin-bottom: 84px;
      gap: 0;
    }
  }

  .paragraph {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .get-profile-button-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 24px;
    }
  }

  .get-profile-button {
    padding: 14px 16px 14px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #2A4F3C;
    color: #FFFFFF;
    border-radius: 8px;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }

  .see-profile-button {
    color: #2A4F3C;
    font-weight: 600;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    cursor: pointer;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
  }

  .social-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-left: 1px solid #A3A3A3;
    margin-top: 16px;
    padding-left: 12px;

    @media (min-width: 768px) {
      margin-top: 44px;
      justify-content: flex-start;
    }
  }

  .social-media-button-list {
    display: flex;
    gap: 12px;

    .social-media-button {
      width: 32px;
      height: 32px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#0A0A0A, 0.1);
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .hero-image {
    width: 100%;
    aspect-ratio: 3 / 2;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../../assets/images/home-hero-image.png);
    background-position: center;
    background-size: contain;
    position: relative;
    overflow: hidden;
    border-radius: 16px;

    @media (min-width: 768px) {
      margin-top: 0;
      width: 100%;
      max-width: 560px;
    }

    .video-player {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    .play-button {
      height: 48px;
      padding: 14px;
      gap: 4px;
      border-radius: 8px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-family: Roboto, sans-serif;
      line-height: 20px;
      cursor: pointer;
      border: none;
      z-index: 1;
    }

    .video-controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      z-index: 1;
      border-radius: 16px;

      &:not(.is-playing) {
        opacity: 1;
      }

      .control-button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .control-icon {
        width: 48px;
        height: 48px;
        color: white;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
        opacity: 0.9;
        transition: transform 0.2s ease, opacity 0.2s ease;

        &:hover {
          transform: scale(1.1);
          opacity: 1;
        }
      }
    }

    .time-control {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 32px 24px 16px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 50%, transparent 100%);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      z-index: 2;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .progress-bar {
        width: 100%;
        height: 3px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 1.5px;
        cursor: pointer;
        position: relative;
        transition: height 0.2s ease;

        .progress {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background: #FFFFFF;
          border-radius: 1.5px;
        }
      }

      .time-display {
        color: white;
        font-size: 0.75rem;
        margin-top: 8px;
        font-family: Roboto, sans-serif;
        opacity: 0.9;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      }
    }

    &:hover {
      .video-controls,
      .time-control {
        opacity: 1;
      }

      .video-controls {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
