<template>
  <div class="home-page">
    <HomePageHero />
    <template v-if="isInitialRenderComplete">
      <AboutOurPlatform />
      <WhatServiceContains />
      <div id="how-it-works">
        <HowItWorks />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <div class="full-width" id="search">
        <MemorialProfileSearch />
      </div>
      <div id="partners">
        <OurPartnersAdditionalServices />
      </div>
      <div class="full-width" id="faq">
        <Faq />
      </div>
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent, ref, onMounted, nextTick } from 'vue';

// Eagerly loaded components (critical for navigation)
import HomePageHero from './components/HomePageHero.vue';
import MemorialProfileSearch from './components/MemorialProfileSearch.vue';
import Faq from './components/Faq.vue';
import AboutOurPlatform from './components/AboutOurPlatform.vue';
import WhatServiceContains from './components/WhatServiceContains.vue';

// Lazy loaded components (non-critical)
const HowItWorks = defineAsyncComponent({
  loader: () => import('./components/HowItWorks.vue'),
  loadingComponent: { template: '<div class="loading-placeholder"></div>' }
});
const Testimonials = defineAsyncComponent({
  loader: () => import('./components/Testimonials.vue'),
  loadingComponent: { template: '<div class="loading-placeholder"></div>' }
});
const OurPartnersAdditionalServices = defineAsyncComponent({
  loader: () => import('./components/OurPartnersAdditionalServices.vue'),
  loadingComponent: { template: '<div class="loading-placeholder"></div>' }
});

export default {
  name: 'HomePage',
  components: {
    // Critical components loaded eagerly
    HomePageHero,
    AboutOurPlatform,
    WhatServiceContains,
    MemorialProfileSearch, // Critical for navigation
    Faq, // Critical for navigation
    // Lazy loaded components (non-critical)
    HowItWorks,
    Testimonials,
    OurPartnersAdditionalServices
  },
  setup() {
    const isInitialRenderComplete = ref(false);
    const isScrolling = ref(false);

    const preloadComponents = async () => {
      // Preload remaining lazy components
      await Promise.all([
        import('./components/HowItWorks.vue'),
        import('./components/Testimonials.vue'),
        import('./components/OurPartnersAdditionalServices.vue')
      ]);
      await nextTick();
    };

    const scrollToSection = async (sectionId) => {
      if (isScrolling.value) return;
      isScrolling.value = true;

      // For non-critical sections, preload components before scrolling
      if (!['search', 'faq'].includes(sectionId)) {
        await preloadComponents();
      }

      const element = document.getElementById(sectionId);
      if (!element) {
        isScrolling.value = false;
        return;
      }

      // Wait a bit for components to render
      await new Promise(resolve => setTimeout(resolve, 100));

      const offset = sectionId === 'faq' ? 300 : 80;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });

      // Reset scrolling flag after animation
      setTimeout(() => {
        isScrolling.value = false;
      }, 1000);
    };

    onMounted(async () => {
      isInitialRenderComplete.value = true;

      // If there's a hash, handle navigation
      if (window.location.hash) {
        const targetId = window.location.hash.substring(1);
        await scrollToSection(targetId);
      }
    });

    return {
      isInitialRenderComplete,
      scrollToSection
    };
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  min-height: 100vh;
  width: 100%;
  background-image: url('@/assets/images/homepage-background-image-mobile.png');
  background-position: right top -88px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  @media (min-width: 768px) {
    background-image: url('@/assets/images/homepage-background-image.png');
    background-size: 72%;
  }

  .full-width {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.loading-placeholder {
  min-height: 200px;
  width: 100%;
  display: block;
}

.observer-element {
  min-height: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
