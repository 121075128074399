<template>
  <section class="section what-service-contains">
    <div class="what-service-contains-content">
      <div class="section-header">
        <h3 class="section-title ">{{ sectionTexts.title }}</h3>

        <h2 class="section-description">{{ sectionTexts.description }}</h2>
      </div>

      <div class="paragraphs">
        <div
          v-for="(paragraph, index) in sanitizedParagraphs"
          :key="index"
          class="paragraph"
        >
          <div
            class="paragraph-image"
            :style="{ backgroundImage: `url(${paragraph.imagePath})` }"
          ></div>

          <h4 class="paragraph-title">{{ paragraph.title }}</h4>

          <p class="paragraph-description" v-html="paragraph.description"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DOMPurify from 'dompurify';
import accessTimeImage from '@/assets/images/20-years-access.png';
import medallionsImage from '@/assets/images/medallions.png';
import privacyImage from '@/assets/images/privacy.png';
import shippingImage from '@/assets/images/shipping.png';

export default {
  name: 'WhatServiceContains',
  setup() {
    const buyProfileUrl = 'https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl';

    const sectionTexts = {
      title: 'Co zawiera usługa?',
      description: 'Zapewniamy:',
      paragraphs: [
        {
          title: 'Przekazywanie historii',
          description: 'Dostęp i edycja Profilu Pamięci obejmują 20 lat, z możliwością cyklicznego odnawiania, by utrwalać rodzinne historie.',
          imagePath: accessTimeImage,
        },
        {
          title: 'Medalion QR',
          description: 'Trwałe, metalowe medaliony dostępne w dwóch kolorach: złotym i srebrnym.',
          imagePath: medallionsImage,
        },
        {
          title: 'Prywatność profilu',
          description: 'Do wyboru profil publiczny lub prywatny zabezpieczony hasłem.',
          imagePath: privacyImage,
        },
        {
          title: 'Darmowa przesyłka',
          description: `W ciągu 48h od <a class="link" href="${buyProfileUrl}">złożenia zamówienia</a>.`,
          imagePath: shippingImage,
        }
      ],
    };

    return {
      sectionTexts
    };
  },
  computed: {
    sanitizedParagraphs() {
      return this.sectionTexts.paragraphs.map(paragraph => ({
        ...paragraph,
        description: DOMPurify.sanitize(paragraph.description)
      }));
    }
  },
};
</script>

<style scoped lang="scss">
.what-service-contains {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  margin-top: 40px;

  @media (min-width: 768px) {
    display: block;
    padding: 0;
  }

  .what-service-contains-content {
    border: 1px solid #A3A3A3;
    border-radius: 16px;
    padding: 40px 24px;

    @media (min-width: 768px) {
      padding: 108px;
    }
  }

  .section-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (min-width: 768px) {
      gap: 12px;
      max-width: 720px;
      margin-bottom: 48px;
    }

    .section-title {
      font-family: 'Source Serif 4', serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;

      @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }

    .section-description {
      font-family: 'Source Serif 4', serif;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 2rem;
      padding-bottom: 16px;

      @media (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 3.5rem;
        max-width: 879px;
        padding-bottom: 0;
      }
    }
  }

  .paragraphs {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 768px) {
      padding: 0;
      flex-direction: row;
    }
  }

  .paragraph {
    font-family: 'Source Serif 4', serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    gap: 0;
    cursor: default;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      line-height: 2rem;
      width: 240px;
    }

    &:hover {
      .paragraph-image {
        background-size: 110%;
      }
    }

    .paragraph-image {
      border-radius: 8px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      width: 100%;
      aspect-ratio: 3 / 2;
      margin-bottom: 8px;
      transition: background-size 0.3s ease;

      @media (min-width: 768px) {
        height: 160px;
        margin-bottom: 20px;
      }
    }

    .paragraph-title {
      font-family: 'Source Serif 4', serif;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      text-align: start;
      margin-bottom: 4px;

      @media (min-width: 768px) {
        font-size: 1.25rem;
        margin-bottom: 12px;
      }
    }

    .paragraph-description {
      font-family: Roboto, sans-serif;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 400;
      text-align: start;

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }

    :deep(.link) {
      color: #0F4320;
    }
  }
}
</style>
