<template>
  <v-card class="profile-card">
    <div class="profile-title">
      <img class="profile-image" alt="profile image" src="../assets/images/avatar-40x40.svg">
    </div>

    <div>
      <p class="profile-number">Profil #55-02-03</p>
      <h3 class="profile-name">Jan Kowalski</h3>
    </div>
  </v-card>
</template>

<script setup>

</script>

<style scoped lang="scss">
.profile-card {
  width: 100%;
  max-width: 321px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 4px 4px 16px 0 rgba(#000000, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (min-width: 768px) {
    max-width: 100%;
  }

  .profile-number {
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    color: #737373;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  .profile-name {
    font-family: 'Source Serif 4', serif;
    font-size: 1rem;
    color: #171717;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}
</style>