<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container hobbies-page-container">
      <div class="hobbies-header">
        <v-icon>
          <img class="top-icon" alt="" src="@/assets/images/icon-bulb-circle-32x32.svg">
        </v-icon>

        <h2 class="big-text">Zainteresowania</h2>
      </div>
      <div class="hobbies-container">
        <swiper
          :fade-effect="{ crossFade: true }"
          :loop="true"
          :slide-active-class="'slide-active'"
          slides-per-view="auto"
          :space-between="10"
          :autoplay="{ delay: 1000, pauseOnMouseEnter: true }"
          class="swiper-slider"
        >
          <swiper-slide v-for="(hobby, index) in hobbyItems" :key="hobby.id" class="swiper-slide hobby">
            <div class="slider-content">
              <div class="hobby-image-wrapper">
                <img
                  v-if="hobby.photoUrl"
                  class="hobby-image"
                  :src="apiUrl + hobby.photoUrl"
                  alt="Hobby Image"
                  @click="openLightbox(index)"
                />

                <v-icon v-else class="hobby-image-placeholder-wrapper">
                  <img class="hobby-image-placeholder" alt="" src="@/assets/images/icon-bulb-circle-32x32.svg">
                </v-icon>
              </div>
              <p class="hobby-name">{{ hobby.name }}</p>
              <p class="hobby-description">{{ hobby.description }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <VueEasyLightbox
        :visible="visible"
        :imgs="[currentImg]"
        :index="0"
        @hide="visible = false"
      />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'HobbiesPage',
  components: {
    Swiper,
    SwiperSlide,
    VueEasyLightbox,
  },
  props: {
    hobbyItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      visible: false,
      currentImg: '',
    };
  },
  methods: {
    openLightbox(index) {
      this.currentImg = this.apiUrl + this.hobbyItems[index].photoUrl;
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hobbies-page-container {
  padding: 0 60px !important;
  max-width: 800px;
}

/* Hide navigation arrows */
.vue-easy-lightbox .btn-next,
.vue-easy-lightbox .btn-prev {
  display: none;
}

.hobbies-header {
  text-align: center;
}

.hobbies-container {
  display: flex;
  overflow-x: hidden;
  margin: 20px 16px 0 !important;

  @media(min-width: 768px) {
    height: fit-content !important;
    width: calc(100% + 60px);
  }
}

.hobby {
  min-width: 230px;
  max-width: 230px;
  height: 100%;
  margin: 0 0 0 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 16px;
  text-align: left;
  display: flex;
  flex-grow: 1;

  @media (min-width: 768px) {
    height: 100%;
    flex-grow: 0;
    max-height: 700px;
  }
}

.hobby-image-wrapper {
  height: 134px;
  display: flex;
  align-self: center;
  margin-bottom: 16px;
}

.hobby-image {
  width: 100%;
  object-fit: cover;

  &:hover {
    cursor: zoom-in;
  }
}

.hobby-image-placeholder-wrapper {
  height: 134px;
  width: 100%;
  margin-left: -6px;
}

.hobby-image-placeholder {
  height: 100%;
}

.hobby-description {
  font-size: 1rem;
  color: #404040;
  line-height: 1.3;
  letter-spacing: 0.1px;
  font-family: Roboto, sans-serif;
  height: 100%;
  overflow-y: auto;
  padding: 0 8px 24px 0;

  @media (min-width: 768px) {
    max-height: 500px;
    padding-right: 0;
  }
}

.hobby-name {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #171717;
  margin: 0 0 8px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
</style>
