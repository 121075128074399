<template>
  <section class="section our-partners-additional-services">
    <div class="content">
      <h2 class="title">{{ sectionTexts.title }}</h2>

      <ul class="service-list">
        <li class="service" v-for="service in sectionTexts.services" :key="service.name">
          <div class="service-icon">
            <img :src="service.icon" alt="Service Icon" />
          </div>

          <div class="service-name">{{ service.name }}</div>
        </li>
      </ul>

      <div class="partners-count-container">
        <div class="partners-count">{{ sectionTexts.partnersCount }}</div>

        <p class="partners-count-message">
          <span>{{ sectionTexts.partnersCountMessage }}</span>

          <span>{{ sectionTexts.findNearestPartnersOnMap }}</span>
        </p>
      </div>
    </div>

    <div class="map-container">
      <iframe
        class="map"
        :src="mapUrl"
        width="532"
        height="500"
        allowfullscreen="allowfullscreen"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    <div class="partners-count-container mobile">
      <div class="partners-count">{{ sectionTexts.partnersCount }}</div>

      <p class="partners-count-message">
        <span>{{ sectionTexts.partnersCountMessage }}</span>

        <span>{{ sectionTexts.findNearestPartnersOnMap }}</span>
      </p>
    </div>
  </section>
</template>

<script>
import montageIcon from '@/assets/images/service-montage-36x36.svg';
import helpIcon from '@/assets/images/service-help-36x36.svg';
import familyTreeIcon from '@/assets/images/service-family-tree-36x36.svg';

export default {
  name: 'OurPartnersAdditionalServices',
  setup() {
    const mapUrl = "https://www.google.com/maps/d/u/3/embed?mid=1yCwu_ZQE7CIdFRTeyAa8VR3M-nEJ_8M&ehbc=2E312F&noprof=1";
    const sectionTexts = {
      title: 'Dodatkowe usługi oferowane przez autoryzowanych partnerów:',
      services: [
        { icon: montageIcon, name: 'Profesjonalny montaż medalionu' },
        { icon: helpIcon, name: 'Pomoc w edycji Profilu' },
        { icon: familyTreeIcon, name: 'Badanie genealogiczne umieszczone na profilu' },
      ],
      partnersCount: '>50',
      partnersCountMessage: 'autoryzowanych przez takzyli.pl miejsc w całej Polsce!',
      findNearestPartnersOnMap: 'Znajdź najbliższe na mapie.',
    };

    return {
      mapUrl,
      sectionTexts,
    };
  }
};
</script>

<style scoped lang="scss">
.our-partners-additional-services {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px;
  font-family: 'Source Serif 4', serif;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 120px 110px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;

    @media (min-width: 768px) {
      gap: 48px;
      justify-content: space-between;
    }
  }

  .title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2rem;

    @media (min-width: 768px) {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
  }

  .service-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
      gap: 24px;
      margin-bottom: 0;
    }

    .service {
      display: flex;
      align-items: center;
      gap: 12px;

      @media (min-width: 768px) {
        height: 36px;
        align-items: flex-start;
      }

      .service-icon {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;

          @media (min-width: 768px) {
            width: 36px;
            height: 36px;
          }
        }
      }

      .service-name {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;

        @media (min-width: 768px) {
          font-size: 1.25rem;
          line-height: 2rem;
        }
      }
    }
  }

  .partners-count-container {
    display: none;
    border-left: 1px solid #A3A3A3;
    padding-left: 20px;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      padding: 16px 0 16px 20px;
      gap: 12px;
    }

    &.mobile {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        display: none;
      }
    }

    .partners-count {
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.75rem;
      margin-bottom: 4px;

      @media (min-width: 768px) {
        margin-bottom: 0;
        font-size: 3.5rem;
      }
    }

    .partners-count-message {
      display: flex;
      flex-direction: column;
    }
  }

  .map {
    border: none;
    border-radius: 16px;
    width: 100%;
    height: 400px;

    @media (min-width: 768px) {
      height: 100%;
      width: 400px;
    }

    @media (min-width: 1024px) {
      height: 500px;
      width: 532px;
    }
  }
}
</style>
