<template>
  <div class="memorial-profile-list-container">
    <div class="page-content">
      <h1 class="title" v-html="sectionTexts.title"></h1>

      <p class="description">{{ sectionTexts.description }}</p>

      <div class="profile-list">
        <MemorialMultiProfileListItem />
        <MemorialMultiProfileListItem />
        <MemorialMultiProfileListItem />
      </div>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/auth";
import {useMemorialProfileStore} from "@/stores/memorial-profile";
import MemorialMultiProfileListItem from "@/views/MemorialMultiProfileListItem.vue";
import DOMPurify from "dompurify";

export default {
  components: {
    MemorialMultiProfileListItem
  },
  props: {
    slotNumber: {
      type: String,
      required: true,
    },
  },
  setup() {
    const authStore = useAuthStore();
    const memorialProfile = useMemorialProfileStore();
    const sectionTexts = {
      title: DOMPurify.sanitize('Wybierz profil,<br>który chcesz zobaczyć'),
      description: 'Do tego kodu QR przypisany jest więcej niż jeden profil. Kliknij w jeden z kafelków, aby przejść dalej:'
    }

    return {
      authStore,
      memorialProfile,
      sectionTexts,
    };
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      error: null,
    };
  },
  mounted() {
    this.fetchMemorialProfile();
  },
  methods: {
    async fetchMemorialProfile() {
      try {
        const store = useMemorialProfileStore();
        this.memorialProfile = store._memorialProfile;

        if (!this.memorialProfile.sections) {
          this.memorialProfile.sections = [];
        }

        if (!this.memorialProfile.sections.find(section => section.key === 'work')) {
          this.memorialProfile.sections.push({ category: 'work', items: [] });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.memorial-profile-list-container {
  height: calc(100vh - 40px); /* Adjusted for better responsiveness */
  width: 100%; /* Adjusted for better responsiveness */
  padding: 32px 16px;
  overflow-y: scroll; /* Enable vertical scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-snap-type: y mandatory; /* Enable full section scroll snapping */
  background-image:
    url('../assets/images/leaves-top-left.png'),
    url('../assets/images/leaves-bottom-left.png'),
    url('../assets/images/leaves-top-right.png'),
    url('../assets/images/leaves-bottom-right.png');
  background-position: top 350px left, bottom left, top 60px right, bottom right;
  background-repeat: no-repeat;
  background-size: 30%;
  background-attachment: fixed, scroll, fixed, scroll;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    background-position: left center, bottom left, top right, bottom right;
    background-size: auto;
    height: calc(100vh - 80px);
    padding: 40px 0 80px;
  }

  .page-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (min-width: 768px) {
      width: 100%;
      max-width: 500px;
      padding: 0 60px;
    }
  }

  .title {
    font-family: 'Source Serif 4', serif;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
  }

  .description {
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
.memorial-profile-container::-webkit-scrollbar {
  display: none;
}

.memorial-profile-container > * {
  scroll-snap-align: start; /* Align children to the start */
}

.profile-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-top: 16px;
}
</style>
